import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import ReactCodeMirror from '@uiw/react-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import io from 'socket.io-client';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";


function App() {
  const [privateKey, setPrivateKey] = useState([

  ]);
  const [tokenAddress, setTokenAddress] = useState("");
  const [market, setMarket] = useState("");
  const [slippage, setSlippage] = useState(10);
  const [tip, setTip] = useState(0.01);
  const [amount, setAmount] = useState(0);
  const [percent, setPercent] = useState(100);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [isImportLoading, setIsImportLoading] = useState(false);
  const [isBuyLoading, setIsBuyLoading] = useState(false);
  const [isSellLoading, setIsSellLoading] = useState(false);
  const [socketId, setSocketId] = useState('');
  const [isEyesOpen, setIsEyesOpen] = useState(false);

  const [log, setLog] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  useEffect(() => {
    var socket = io('https://server.solmarketmaker.com', { transports: ['websocket'] });
    socket.on('connect', () => {
      console.log('Connected to server', socket.id);
      setSocketId(socket.id);
    });

    socket.on('data', data => {
      setLog(prevLog => { return [data, ...prevLog] });
    });

    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    getMint();
  }, [market]);

  useEffect(() => {
    getBalance();
  }, [tokenAddress])

  useEffect(() => {
    setData({
      ...data,
      data: data.data.map(item => {
        return {
          ...item,
          isSelected: selectedAll
        }
      })
    })
  }, [selectedAll])

  async function getMint() {
    if (!market) return;
    //make post request to get data
    const response = await fetch('https://server.solmarketmaker.com/getMint', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ market }),
    }).then(response => response.json());
    if (response.mint) {
      setTokenAddress(response.mint);
      setLog([{
        time: new Date().toLocaleTimeString(),
        type: 0,
        value: `Token detected: ${response.mint} name: ${response.name} symbol: ${response.symbol}`,
        status: 1
      }, ...log]);
    }

  }

  async function onImportClicked() {
    setIsImportLoading(true);
    try {
      await getBalance();
    } catch (error) {

    }
    finally {
      setIsImportLoading(false);
    }
  };

  async function onRefresh() {
    setIsRefreshLoading(true);
    setData({
      "data": [],
      "sum": {
        "solBalance": 0,
        "worth": 0
      }
    });
    try {
      await getBalance();
    } catch (error) {

    }
    finally {
      setIsRefreshLoading(false);
    }
  }

  function onSelectedAllChecked(e) {
    setSelectedAll(!selectedAll);
  }

  function onPrivateKeysChange(e) {
    setPrivateKey(e.split('\n'));
  };

  function onItemChecked(item) {
    setData({
      ...data,
      data: data.data.map(dataItem => {
        if (dataItem.publicKey === item.publicKey) {
          return {
            ...dataItem,
            isSelected: !dataItem.isSelected
          }
        }
        return dataItem;
      })
    });
    // setSelectedAll(false);
  }

  async function onBuyClick() {
    setIsBuyLoading(true);
    //fetch all selected items
    const privateKeys = data.data.filter(item => item.isSelected).map(item => item.privateKey);
    await fetch('https://server.solmarketmaker.com/buy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Socket-ID': socketId,
      },
      body: JSON.stringify({
        market,
        privateKeys,
        amount,
        tip,
        slippage
      }),
    });
    setLog([{
      time: new Date().toLocaleTimeString(),
      type: 0,
      value: `Submit buying ${amount} SOL, tip: ${tip} SOL, wallets: ${privateKeys.length}`,
      status: 1
    }, ...log]);
    // console.log(response);
    //add response to first element of log
    // setLog([response, ...log]);
    // await getBalance();
    setIsBuyLoading(false);
  }

  async function onSellClick() {
    setIsSellLoading(true);
    //fetch all selected items
    const privateKeys = data.data.filter(item => item.isSelected).map(item => item.privateKey);
    await fetch('https://server.solmarketmaker.com/sell', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Socket-ID': socketId,
      },
      body: JSON.stringify({
        market,
        privateKeys,
        percent,
        tip,
        slippage,
      }),
    })
    // console.log(response);
    //add response to first element of log
    setLog([{
      time: new Date().toLocaleTimeString(),
      type: 0,
      value: `Submit selling ${percent}%, tip: ${tip} SOL, wallets: ${privateKeys.length}`,
      status: 1
    }, ...log]);
    // await getBalance();
    setIsSellLoading(false);
  }

  async function getBalance() {
    //make post request to get data
    const response = await fetch('https://server.solmarketmaker.com/fetchBalance', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        market,
        privateKeys: privateKey,
        socketId
      }),
    }).then(response => response.json())
    setData(response);
    setSelectedAll(false);
  }


  const [data, setData] = useState({
    "data": [],
    "sum": {
      "solBalance": 0,
      "worth": 0
    }
  });
  return (
    <Container fluid className='h-100'>
      <Row className='text-center bg-grey text-success'>
        <Col>
          <h1>Market Marker</h1>
        </Col>
      </Row>
      <Row className='text-center mt-2'>
        <Col>
          {/* <Form.Control as="textarea" placeholder='Input private key' onChange={e => onPrivateKeysChange(e)} /> */}
          {/*Do blur ReactCodeMirror Content*/}
          <div className='bg-grey'>
            <div className={!isEyesOpen ? 'blur' : ''} style={{ textAlign: 'left', overflow: 'auto' }}>
              <ReactCodeMirror
                minHeight='300px'
                maxHeight='300px'
                onChange={onPrivateKeysChange}
                value={privateKey.join('\n')}
              />
            </div>
            {isEyesOpen ? <FaRegEye className='eyes' onClick={() => { setIsEyesOpen(false) }} /> : <FaRegEyeSlash className='eyes' onClick={() => { setIsEyesOpen(true) }} />}
          </div>
          <Button className='mt-2' variant="primary" onClick={onImportClicked}>
            {isImportLoading ?
              <div class="spinner-border" role="status">
              </div> : "Import"}
          </Button>
        </Col>
      </Row>
      <Row className='mt-3 h-75'>
        <Col md="7">
          <Row className='overflow-auto' style={{ height: '363px' }}>
            <Col md={12}>
              <Row className='border'>
                <Col md={1}>
                  <Form.Check type="checkbox" checked={selectedAll} onChange={e => onSelectedAllChecked(e)} />
                </Col>
                <Col md={5} className='text-center'><b>Address</b></Col>
                <Col md={2}><b>Balance</b></Col>
                <Col md={4}><b>Token</b></Col>
              </Row>
            </Col>
            {
              data && data.data.map((item, index) => {
                return (
                  <Col md={12} key={index}>
                    <Row className='border'>
                      <Col md={1}>
                        {/* <Row> */}
                        <Form.Check label={index + 1} type="checkbox" checked={item.isSelected} onChange={e => onItemChecked(item)} />
                        {/* {index + 1} */}
                        {/* </Row> */}
                      </Col>
                      <Col md={5}> <a style={{ color: 'black' }} href={'https://solscan.io/account/' + item.publicKey} target='_blank'>{item.publicKey} </a> </Col>
                      <Col md={2}>{item.solBalance} SOL</Col>
                      <Col md={4}>{item.tokenAmount.toLocaleString()} ({item.worth} SOL)</Col>
                    </Row>
                  </Col>
                );
              })
            }
          </Row>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={1}></Col>
                <Col md={5} className='text-center'><b>Sum</b></Col>
                <Col md={2}><b>{data.sum.solBalance} SOL</b></Col>
                <Col md={4}><b>{data.sum.worth} SOL</b></Col>
              </Row>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col md="2" className='mt-2'>
              <Button variant="primary" disabled={isRefreshLoading} onClick={onRefresh} className='w-100 h-100'>
                {isRefreshLoading ?
                  <div class="spinner-border" role="status">
                  </div> : "Refresh"}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md="5">
          <Row>
            {/* <Col md="12">
              <Form.Group>
                <Form.Label className='text-danger'><b>RPC</b></Form.Label>
                <Form.Control placeholder="Enter rpc url" value={tokenAddress} />
              </Form.Group>
            </Col> */}
            <Col md="12">
              <Form.Group>
                <Form.Label className='text-danger'><b>Token Mint</b></Form.Label>
                <Form.Control placeholder="Enter token mint" disabled value={tokenAddress} />
              </Form.Group>
            </Col>
            <Col md="12" className='mt-2'>
              <Form.Group>
                <Form.Label className='text-danger'><b>Pool(Pair)</b></Form.Label>
                <Form.Control placeholder="Enter liquidity pool address" value={market} onChange={(e) => {
                  setMarket(e.target.value);
                }} />
              </Form.Group>
            </Col>
            <Row className='mt-2'>
              <Col md="6">
                <Form.Group>
                  <Form.Label className='text-danger'><b>Slippage</b></Form.Label>
                  <Form.Control type='number' placeholder="Slippage" value={slippage} onChange={(e) => {
                    setSlippage(+e.target.value);
                  }} />
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group>
                  <Form.Label className='text-danger'><b>Tip (SOL)</b></Form.Label>
                  <Form.Control type='number' placeholder="Slippage" value={tip} onChange={(e) => {
                    setTip(+e.target.value);
                  }} />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col md="6">
                <Form.Group className='mt-2'>
                  <Container className='border' style={{ height: '160px' }}>
                    <Container className='text-center'>
                      <Form.Label className='text-success'><b>Buy</b></Form.Label>
                    </Container>
                    <Container>
                      <Form.Label><b>Amount SOL</b></Form.Label>
                      <Form.Control type='number' placeholder="Amount" value={amount} onChange={(e) => {
                        setAmount(+e.target.value);
                      }} />
                    </Container>
                    <Container className='text-center'>
                      <Button className='btn-success w-50 mt-2 mb-2' disabled={!tokenAddress || !amount} onClick={onBuyClick}>
                        {isBuyLoading ?
                          <div class="spinner-border" role="status">
                          </div> : "Buy"}
                      </Button>
                    </Container>
                  </Container>
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group className='mt-2'>
                  <Container className='border' style={{ height: '160px' }}>
                    <Container className='text-center'>
                      <Form.Label className='text-danger'><b>Sell</b></Form.Label>
                    </Container>
                    <Container>
                      <Form.Label><b>Sell Percent ({percent + '%'})</b></Form.Label>
                      <Form.Range className='range' style={{ height: '32px' }} max={100} min={10} value={percent} onChange={(e) => {
                        setPercent(+e.target.value);
                      }} />
                    </Container>
                    <Container className='text-center'>
                      <Button className='btn-danger w-50 mt-2 mb-2' disabled={!tokenAddress} onClick={onSellClick}
                      > {isSellLoading ?
                        <div class="spinner-border" role="status">
                        </div> : "Sell"}</Button>
                    </Container>
                  </Container>
                </Form.Group>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
      <Row className='mt-2'>
        <Container>
          <Col md="12">
            <Form.Label><b>Logs</b></Form.Label>
          </Col>
          <div className='logs ml-2 mr-2' style={{ overflow: 'auto', minHeight: '350px', maxHeight: '350px' }} >
            {log?.map((item, index) => {
              return (
                <span>
                  <span className='text-success'><b>{item.time + ': '}</b></span>
                  {item.type === 1 ? (
                    <a className={item.status === 1 ? 'text-success' : item.status === 0 ? 'text-danger' : 'text-info'
                    } key={index} href={`https://explorer.jito.wtf/bundle/${item.value}`} target='_blank'><span>Bundle: {item.value}</span></a>) : (
                    <span className={item.status === 1 ? 'text-success' : item.status === 0 ? 'text-danger' : 'text-info'
                    } key={index}><span>{item.value}</span></span>
                  )}
                </span>
              );
            })}
          </div>
        </Container>
      </Row >
    </Container >
  );
}

export default App;